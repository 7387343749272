<template>
    <div class="container pt-lg-5">
      <header>
        <img src="~@/assets/img/logo-b-b.png" class="bg-big">
        <img src="~@/assets/img/logo-b-s.png" class="bg-small">
        <h1>魯凱族藥草園知識平台</h1>
      </header>
      <article>
        <div class="img-round mt-3 mb-5">
          <img class="round-01" src="~@/assets/img/img-round-01.png">
          <img class="round-00" src="~@/assets/img/img-round-00.png">
          <img class="round-02" src="~@/assets/img/img-round-02.png">
        </div>
      </article>
      <article>
        <div class="row">
          <div class="form-area col-12">
            <h3 data-toggle="collapse" href="#collapSearch" aria-expanded="true"><span class="switch">關鍵字查詢</span></h3>
            <div class="collapse show" id="collapSearch">
              <div class="row mb-2">
                <div class="col-12 col-lg-9 my-2">
                  <input type="text" class="form-control" v-model="keyword" placeholder="請輸入關鍵字" @keyup.enter="btnSearch"/>
                </div>
                <div class="col-12 col-lg-3 my-2">
                  <button class="btn btn-grassgreen w-100" @click="btnSearch">查詢</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      keyword: '',
    }
  },
  computed: {
    isRukaiHome () {
      return this.$route.path.toLowerCase() === '/'
    },
  },
  methods: {
    btnSearch () {
      this.$router.push({
        name: 'RukaiHerbalSearch',
        query: { keyword: this.keyword },
      })
    },
  },
}
</script>
